<template>
  <div>
    <my-nav-bar
      title="选择客户"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-search-container">
      <van-search v-model="listQuery.client_name" class="search-bar" clearable placeholder="请输入客户名称" @search="onSearch" />
      <van-list
        ref="list"
        v-model="loading"
        class="list"
        :finished="finished"
        @load="getList"
      >
        <van-empty v-if="showEmpty" description="暂无数据" />
        <van-cell v-for="client in list" :key="client.id" :title="client.name" is-link center @click="chooseClient(client)" />
      </van-list>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getClients } from '@/api/client-unpaid'
export default {
  components: { myNavBar },
  data() {
    return {
      listQuery: {
        page: 0,
        limit: 20,
        client_name: '',
        store_id: this.$route.query.store_id
      },
      list: [],
      loading: false,
      finished: false,
      showEmpty: false
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      getClients(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list < this.listQuery.limit
        this.showEmpty = this.list.length === 0
      })
    },
    onSearch() {
      this.listQuery.page = 0
      this.loading = false
      this.finished = false
      this.showEmpty = false
      this.list = []
      this.$refs.list.check()
    },
    chooseClient(client) {
      this.$eventBus.$emit('handle-iou', client)
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.search-bar {
    position: fixed;
    top: 46px;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .list {
    margin-top: 95px;
  }
</style>
